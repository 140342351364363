<template>
   <div>
     <v-banner
        single-line
        class="banner"
    >
      <v-img
          src="https://h5.ophyer.cn/official_website/banner/h5LearnCloud-banner1-2.png"
          height="100%"
          :transition="false"
          @load="handleLoad"
          class="d-flex align-center"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              size="80"
              color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="d-flex  max-width-1200">
           <v-card color="transparent banner-text ml-6" flat>
            <v-card-title class="white--text pa-0 font-size-h5banner-title">
              万学云
            </v-card-title>
            <v-card-title class="white--text pa-0 mt-2 font-size-h5banner-tip">
              一站式教学、运营、<br/>管理系统 
            </v-card-title>
          </v-card>
        </v-container>
      </v-img>
    </v-banner>
    <!-- 基础教育产品体系 -->
    <v-card flat class="py-6 position-r">
      <v-card-title class="justify-center font-weight-bold font-size-20 color-#333333 pa-0 line-height-28">基础教育产品体系</v-card-title>
      <v-tabs v-model="tab" background-color="transparent" class="xs-tab" >
        <v-tab v-for="(v, k) in list" :key="k" class="pa-0"
               :class="[k === 0 ? 'ml-5':'ml-6',k === list.length - 1 ? 'mr-5':'']"
               style="min-width: unset">
          <b class="font-size-16 letter-spacing">{{ v.title }}</b>
        </v-tab>
      </v-tabs>
      <v-tabs-items class="mt-3 mx-5" v-model="tab" style="border-radius: 10px;box-shadow: 0px 0px 18px 0px rgba(0, 92, 193, 0.16);">
        <v-tab-item v-for="(v, k) in list" :key="k" :transition="false">
          <v-card  color="white" class="pa-5">
            <v-img :src="v.img" width="100%" min-height="200" contain></v-img>
            <v-card-title class="pa-0 mt-4 font-size-18 font-weight-bold">{{v.h2}}</v-card-title>
            <v-card-text class="pa-0 mt-2 font-size-13 color-666" v-text="v.content" v-html="v.content"/>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <div class="tab-btn-prev" @click.stop="handleTabBtn('prev')"></div>
      <div class="tab-btn-next" @click.stop="handleTabBtn('next')"></div>
    </v-card>
    <!-- 产品方案 -->
    <!--<v-card class="wap3 justify-center" :flat="true">-->
    <!--  <v-card-title class="justify-center font-weight-bold font-size-20 color-#333333 pa-0 line-height-28">万学云产品方案</v-card-title>-->
    <!--  <v-container>-->
    <!--    <v-row justify="center"  class="solutions_row pa-2 " :class="$vuetify.breakpoint.name === 'xs' ? '':''">-->
    <!--      <v-col cols="12" v-for="n in solutions" :key="n.id">-->
    <!--        <v-card flat >-->
    <!--          <div class="img_title " justify="center">-->
    <!--             <v-img :src="n.src" class="align-end white&#45;&#45;text">-->
    <!--               <div style="background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);height:85px">-->
    <!--                 <div class="white&#45;&#45;text font-size-16" style="padding:0px 80px 9px 15px;text-align: left;line-height:22px;">{{n.title}}</div>-->
    <!--                 <div class="font-size-13" style="padding:0px 27px 0px 15px;text-align: left;line-height:18px" v-html='n.content'>{{n.content}}</div>-->
    <!--                 <div style="padding-right:16px;float:right"><v-btn class="look_btn" @click="goCaseDetail(n.anli)">详情</v-btn></div>-->
    <!--               </div>-->
    <!--            </v-img>-->
    <!--          </div>-->
    <!--        </v-card>-->
    <!--      </v-col>-->
    <!--    </v-row>-->
    <!--  </v-container>-->
    <!--</v-card>-->
    
    <!-- 优势 -->
    <v-card class="justify-center wap4" :flat="true" style="margin-bottom: 20px;">
      <v-card-title class="justify-center font-weight-bold font-size-20 color-#333333 pa-0 line-height-28">我们的优势</v-card-title>
      <v-container class="max-width-1200 mt-6">
        <v-row justify="center" >  
          <v-col cols="6" v-for="(n,ind) in advantage" :key="ind" class="d-flex justify-center"
                 style="padding: 0 5px;">
            <v-card flat class="advantage_card">
              <div class="img_title" justify="center" :class="ind%2==0?'marginLeft':'marginRight'">
                <v-img :src="n.src" class="align-center" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)">
                  <div class="white--text font-size-16 pl-6 pr-6  font-weight-bold">{{n.title}}</div>
                  <!-- <div class="white--text subtitle-1 pl-6 pr-6 pb-5" style="text-align:left;line-height: 20px;height:55px" v-html='n.content'>{{n.content}}</div> -->
                </v-img>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
   </div>
</template>

<script>
//import qs from 'qs'
export default {
  name: "LearnCloud",
  data(){
    return{
      model: 0,
      tab:null,
      bannerLoad: false,
      imgLoad: false,
      windowHight: window.innerHeight/2,
      list:[
        {title:"VR小学科学",h2:'沉浸式+强交互情景教学 参与感更强',img:'https://h5.ophyer.cn/official_website/other/learn1.png',content:'• 涵盖天文地理生物中小学系列72节<br/> • 强大的交互性，让学生更好地参与知识情景 <br/> • 沉浸式情景教学，营造真实可触的类现实世界'},
        {title:"线上研学课程",h2:'文化、历史、军事研学基地',img:'https://h5.ophyer.cn/official_website/other/learn2.png',content:'• 包含名人故居，历史文物、建筑，纪念展馆等220个基地<br/>• 将教研内容与全景结合，互动式体验，提升教研效果'},
        {title:"中小学课后服务平台",h2:'一站式校内课后服务解决方案',img:'https://h5.ophyer.cn/official_website/other/learn3.png',content:'• 通过新技术将续集与现实技术完美集合，促进教学内容生动性<br/>• 以游戏化细微贯穿，提升学习兴趣，达到全脑记忆'},
        {title:"爱国主义教育",h2:'思想政治党建系列全景课程',img:'https://h5.ophyer.cn/official_website/other/learn4-1.png',content:'• 包括长征、数字展馆及强国之路多种系列课程<br/>• 强交互体验，在全景中感受国家、民族的复兴之路<br/>• 支持微定制、全新定制 '},
        {title:"中小学智慧教室",h2:'虚拟现实智慧，创新教室建设',img:'https://h5.ophyer.cn/official_website/other/learn5-1.png',content:'• 高级智慧教室，100+课程任选<br/>• VR眼镜、3D裸眼屏、中控电脑、中控管理系统一站式服务<br/>• 以第一人称置身于类真实的情景，深化课程体验'},
        
      ],
      solutions: [
        { title: 'VR播控系统', src: 'https://h5.ophyer.cn/official_website/other/learnimg31.jpg', content:'用于教学/实验、拓展体验，方案灵活<br/>，易落地',anli:'bokongxitong'},
        { title: '全景运营创业培训', src: 'https://h5.ophyer.cn/official_website/other/learnimg32.jpg', content:'设备使用教学+全景制作工具培训，享<br/>受平台派单，创业如此简单',anli:'chuangyepeixun'},
      ],
      advantage:[
        { title: '国家政策扶持', src: 'https://h5.ophyer.cn/official_website/other/learnYS1.png', content:'匹配教育部对虚拟现实专业的建设要求'},
        { title: '大平台技术支撑', src: 'https://h5.ophyer.cn/official_website/other/learnYS2.png', content:'虚拟现实应用技术引领者，打造VR<br/>虚拟现实教学平台'},
        { title: '教学资源丰富', src: 'https://h5.ophyer.cn/official_website/other/learnYS3.png', content:'对接主流教育行业，拥有各专业丰富的<br/>教学资源'},
        { title: '完善的课程体系', src: 'https://h5.ophyer.cn/official_website/other/learnYS4.png', content:'完善的课程体系，打造一站式智慧<br/>教育平台'},
        { title: '满足多场景教学', src: 'https://h5.ophyer.cn/official_website/other/learnYS5.png', content:'满足教、学、练、测、评的需求，全场景<br/>教学，提升学习效率'},
        { title: '专业拓展', src: 'https://h5.ophyer.cn/official_website/other/learnYS6.png', content:'依托虚拟现实技术，支持各专业虚拟仿真<br/>训练室的建设'},
      ]
    }
  },
  mounted(){
    this.bannerLoad = true;
  },
  methods: {
    handleLoad (){
      this.imgLoad = true;
    },
    goCaseDetail(item){
      this.$router.push({ 
        name: 'h5CaseDetail',
        query: {
          source: 'qita',
          anli: item
        }
      })
    },
    handleTabBtn: function (type){
      const maxTab = this.list.length - 1;
      if(type === "prev"){
        if(this.tab === 0){
          this.tab = maxTab;
        }else {
          this.tab--;
        }
      }else if(type === "next"){
        if(this.tab === maxTab){
          this.tab = 0;
        }else {
          this.tab++;
        }
      }
    }
  }
}
</script>

<style scoped lang='scss'>
::v-deep .v-card__subtitle, .v-card__text ,.text-subtitle-1{
  line-height: 26px !important;
}
::v-deep .banner{
  position: relative;
   .v-banner__wrapper, .v-banner__content, .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
  .v-card__subtitle, .v-card__text, .v-card__title {
    // padding: 8px 26px;
    // line-height: 21px !important;
  }
}
::v-deep.banner_title {
  font-size: 60px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #FFFFFF !important;
  // padding-top: 10%;
}
::v-deep .xs-tab{
  .v-slide-group__prev,.v-slide-group__next{
    display: none !important;
  }
}
.scence_text{
  color: #FFFFFF !important;
}
.banner_span {
  font-family: Source Han Sans CN;
  color: #2672FF;
  font-size: 20px;
}
::v-deep.solutions_row{
  //width: 960px;
  margin: 0 auto;
}
.warp {
  margin: 0px auto 0;
  text-align: center;
}
::v-deep .v-slide-group__content{
  justify-content: center !important;
}
.warp2 {
  .box {
    width: 1024px;
    margin: 50px auto 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .left {
    margin-right: 55px;
    img {
      width: 400px;
      height: 260px;
      border-radius: 30px;
      object-fit: cover
    }
  }
  .right {
    text-align: start;
    //font-family: SourceHanSansCN-Regular;
    padding-top: 30px;
    .v-card__text {
      padding: 0;
    }
    .title {
      font-family: Source Han Sans CN;
      font-size: 24px !important;
      font-weight: 600 !important;
    }
    .tip {
      margin: 20px 0;
      font-size: 16px !important;
      color: rgb(102, 102, 102) !important;
    }
    .text {
      font-size: 14px;
      line-height: 26px;
    }
  }
}
.wap3{
  background: #F5F8FC;
  padding-top: 29px;
  .img_title {
    // width: 266px;
    margin: 0 auto;
    text-align: center;
    .theme--light.v-image {
      margin: 0px auto 0px;
      border-radius: 10px;
      //width:167px ;
      //height: 260px;
      object-fit:contain;
      //margin-left: 15px;
      margin-bottom: 10px;
    }
    h2 {
      margin-top: 10px;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
    }
    p {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      margin-top: 5px;
      padding: 0px 45px
    }
}
}
.wap4{
  padding-top: 29px;
  
  .marginRight {
    // width: 266px;
    margin: 0 auto;
    text-align: center;
    .theme--light.v-image {
      margin: 0px auto 0px;
      border-radius: 10px;
      //width:167px ;
      //height: 260px;
      object-fit:contain;
      margin-right: 15px;
      //margin-right: 15px;
      margin-bottom: 10px;
    }
    h2 {
      margin-top: 10px;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
    }
    p {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      margin-top: 5px;
      padding: 0px 45px
    }
  }
  .marginLeft{
    margin: 0 auto;
    text-align: center;
    .theme--light.v-image {
      margin: 0px auto 0px;
      border-radius: 10px;
      //width:167px ;
      //height: 260px;
      object-fit:contain;
      margin-left: 15px;
      //margin-right: 15px;
      margin-bottom: 10px;
    }
    h2 {
      margin-top: 10px;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
    }
    p {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      margin-top: 5px;
      padding: 0px 45px
    }
  }
}

// ::v-deep .look_btn{
//   width: 52px;
//   height: 28px !important;
//   background: #FFFFFF;
//   color: #2181EA;
//   box-shadow: 0px 0px 5px 0px rgba(0, 92, 193, 0.3);
//   border-radius: 12px;
//   margin-top: 30px;
// }
::v-deep .v-btn:not(.v-btn--round).v-size--default{
  width: 52px;
  min-width: 52px;
  height: 28px !important;
  background: #FFFFFF;
  color: #2181EA;
  box-shadow: 0px 0px 5px 0px rgba(0, 92, 193, 0.3);
  border-radius: 12px;
  margin-top: -52px;
}
::v-deep .v-carousel__controls{
  display: none !important;
}
.advantage_card{
  transition: 0.3s;
  &:hover {
      transform: scale(1.04);
      box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1) !important;
    }
}
</style>